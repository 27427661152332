@media print {
  html, body {
    background-color: white !important;
  }
  td {
    display: table-cell !important;
  }
}




